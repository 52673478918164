nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 20px;
}

@media screen and (max-width:991px ){
    nav {
        display: none;
    }
}

li {
    padding: 10px 20px;
    list-style: none;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
}
