.gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 10% 10%
}


.galleryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageModal {
    position: absolute;
    background-color: black;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    display: none;
}