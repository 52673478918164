/* li {
    padding: 0;
    list-style-type: circle;
} */

.row .imageContainer img {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
}
.imageContainer img {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 0px;
}
h2 {
    font-size: 17px;
    font-weight: 900;
}
p {
    padding-bottom: 30px;
}

/* footer {
    padding-top: 10px;
} */