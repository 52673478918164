ion-toolbar {
    position: absolute !important;
    --ion-toolbar-background: rgba(255, 255, 255, 0);
    --border-width: 0 !important;
}

ion-content {
  --background: none;
  background: url('/public/assets/images/IMG_2727.jpeg') no-repeat center fixed; 
  background-size: cover;
   }

ion-buttons {
  color: black;
  position: relative;
}

ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }
  
ion-menu.md ion-item ion-icon {
color: #616e7e;
}

ion-menu.ios ion-item ion-icon {
font-size: 24px;
color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
color: var(--ion-color-primary);
}

ion-menu-button {
  color: black;
  margin-right: 20px;
}

@media (min-width:950px ){
 

  h1 {
    margin-top: 100px !important;
  }

  #main {
    position: relative;
    margin-top: 250px !important;
  }
}


#main {
  overflow: scroll;
  position: relative;
}

.pageOverlay {
  height: auto;
  background-color: rgba(255, 255, 255, 0.75);
  margin-left: 0px;
  margin-right: 0px;
}
.aboutContent {
  line-height: 2.0;
  
}

p {
  padding: 0 10%;
  margin-bottom: 0;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  font-family: CinzelRegular;
}

div {
  font-family: CinzelRegular;
  font-weight: bolder;
}

h1 {
  font-family: CinzelRegular;
  text-align: center;
  font-weight: bold;
  font-size: 30px !important;
  padding-top: 30px;
  padding-bottom: 35px;
}
h2 {
    font-family: CinzelRegular;
    text-align: center;
    font-size: 15px;  
    font-weight: bolder;
  }

h3 {
  text-align: center;
  font-size: 15px;  
  letter-spacing: 1px;
  font-family: CinzelRegular;
  font-weight: bolder;
}

.message {
  text-align: center;
  font-size: 25px;  
  margin-top: 60px;
  letter-spacing: 1px;
  font-family: CinzelRegular;
  font-weight: bolder;
}

.aboutHeader {
  font-size: 47px !important;
}

a {
  font-family: CinzelRegular
}

.tagline {
  margin: 0;
}

.imageContainer img {
  margin-top: 70px;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 200%;
  height: 550px;
}
.best {
  padding: 0px 5px;
}

.signature {
  font-family: BonheurRoyale-Regular;
  padding: 0px 0px;
  font-size: 30px;
  
}
/* .home footer {
  margin: 15px;
} */