.contactPage {
    flex-direction: column;
    text-align: center;
    margin: 0 10px 0 10px;
}

.contactPage h1 {
    padding-bottom: 30px;
    
}

.contactPage h3 {
    margin: 5% 10%;
}

.contactPage h4 {
    margin-top: 50px;
    font-size: 25px;
    font-weight: bolder;
    font-family: CinzelRegular;
    padding-bottom: 25px;
    padding: 2% 5%;

}

.location  h4{
    padding-top: 0px;
}

.contactPage div {
    flex-direction: column;
}

.contactPage p {
    line-height: 2.0;
    margin-top: 0px;
    padding: 0.5% 5%;
}


.walk-ins {
    margin-top: 0px;
    font-weight: bold;
}

.email {
    padding-top: 2%;
}

.address p {
    font-size: 15px;
    line-height: .9;
    margin-top: 5px;
    padding: 0px;
}
footer {
    margin: 15px;
}
