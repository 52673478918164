.pageFooter {
    position: absolute;
    top: 0;
    width: 100%;
}

.pageFooter Ion-Icon {

    margin-left: -20px;
    margin-right: -20px;
}

span {
    display: flex;
    justify-content: right;
    align-items: center;
}