.questionWrapper {
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}

.questionContent,
.answerContent {
    line-height: 1.5;
    padding: 2% 10%;
}
.answerContent {
    line-height: 2.0;
    padding: 2% 5% 15%;
}

.questionContent {
    font-weight: 900;
    margin-top: 20px;
    /* padding: 10px; */
}
/* h3 {
   margin-bottom: 10px;
   margin-top: 100px;
    padding: 0% 7%;
} */

h4 {
    text-align: center;
    letter-spacing: 1px;
    font-family: CinzelRegular;
    font-weight: bolder;
    margin-bottom: 80px;
    margin-top: 16px;
    padding: 0% 7%;
    font-size: 20px;  
}
p {
    line-height: 2.0;
    margin-top: 0px;
    padding: 2% 5%;
}
h2 {
    font-size: 25px;
}
.imageContainer img{
    display: flex;
    justify-content: center;
    max-width: 85%;
    height: auto;
}


.imgContainer img {
    float: center;
    width: 100%;
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
}

.imgContainer2 img {
    float: center;
    width: 100%;
    margin-top: 0px;
    /* height: 65%; */
    padding: 5px;
    margin-bottom: 70px;
}
.imageContainer2 img {
    padding: 5px;
}

.img-Container img {
    padding: 10px;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}
/* footer {
    margin: 30px;
  } */

  .gettoknow p {
    margin: -30px;
    padding: 10px;
    text-align: center;
  }

  .gettoknowimage img{
    display: flex;
    justify-content: left;
   padding: 5px;
   margin-bottom: -20px;
   margin-top: 50px;
   margin-left: 40px;
   
}


.gettoknowimage2 img {
    float: right;
    padding: 25px;
    margin: 10px;
}

.gettoknowimage3 img {
    float: right;
    
    margin-top: -20px;
    /* height: 65%; */
    padding: 20px;
    
}
.gettoknowimage4 img {
    padding: 10px;
    margin-right: 15px;
    margin-top: -20px;
}



.row::after {
    content: "";
    clear: both;
    display:table;
}