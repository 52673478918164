.aboutContent {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    text-align: center;
}

.questionContent {
    font-family: CinzelRegular;
    font-size: 18px;
}