.resBtn {
    display: grid;
    margin-top: 25px;
    margin-bottom: 35px; 
    margin-left: 9%;
    margin-right: 9%;
    font-size: 16px;
    font-family: CinzelRegular;
    font-weight: bolder;
}


