@font-face {
    font-family: BrittanySignature;
    src: url("/public/assets/fonts/BrittanySignature.woff2") format("woff2");
}
@font-face {
    font-family: CinzelRegular;
    src: url("/public/assets/fonts/CinzelRegular.woff2") format("woff2");
}
@font-face {
    font-family: Qucksand-Medium;
    src: url("/public/assets/fonts/Quicksand-Medium.woff2") format("woff2");
}
@font-face {
    font-family: BonheurRoyale-Regular;
    src: url("/public/assets/fonts/BonheurRoyale-Regular.woff") format("woff2");
}
  