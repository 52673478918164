.headerLogo {
    margin-top: 50px;
   max-width: 55%;
   
   
}

div {
    display: flex;
    justify-content: center;
}
