.pricelistcontainer {
    display: grid;
    white-space:pre;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10%;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 12px;
    line-height: 1.0;
}

.pricelistcontainer:last-child {
    padding-bottom: 40px;
}

.priceliststyle {
    justify-self: start;
    line-height: 1.5;
    
    /* text-overflow: ; */
   
}
/* .pricelistdescription {
    overflow-wrap: break-word;
    justify-self: start;
    text-align: start; 
} */
 /* img {
   float: left;
   margin-left: 10px;

} */

.extentions h2 {
   padding-top: 150px;
   font-size: 20px;
   text-align: center;
   margin-right: 5px;
   margin-left: 35px;
   padding-bottom: 10px;
}
.extensions img {
    padding-top: 70px;
    margin-left: 15px;
    width: 200px;
    height: 350px;
}
/* .pricelisttitle {
    text-align: right;
    margin-right: 15px;
} */
h3 {
    text-align: center;
    font-size: 15px;
}
.color h2 {
   font-size: 20px;
   text-align: center;
   margin-right: 40px;
   margin-left: 5px;
   padding-bottom: 10px;
   padding-top: 125px;
}
.color img {
    padding-top: 50px;
    margin-left: 5px;
    width: 200px;
    height: 350px;
}
.style h2 {
    padding-top: 150px;
    font-size: 20px;
    text-align: center;
    margin-right: 20px;
    margin-left: 50px;
    padding-bottom: 10px;
 }
 .style img {
     padding-top: 70px;
     margin-left: 10px;
      width: 200px;
    height: 350px;
 }

.pricelistprice {
    line-height: 1.0;
    overflow-wrap: break-word;
    justify-self: end;
    text-align: end; 
}

.policies h2 {
    font-size: 20px;  
}
.policyHeader {
    padding-top: 56px;
    padding-bottom: 40px;
}

.policies {
    flex-direction: column;
}

.extensionsprice p {
    font-size: 11px;
    text-transform: uppercase;
    font-family: CinzelRegular;
}
.colorprice p {
    font-size: 11px;
    text-transform: uppercase;
    font-family: CinzelRegular;
}
.policies p {
    padding-top: 10px;
    padding-bottom: 60px;
}
/* footer {
    margin: 25px;
} */